<template>
  <div>
    <div class="comment">
      <div class="content-comment" v-if="!question.mask">
        <app-form-textarea
          :color="survey.colors.splashColor"
          :placeholder="question.placeholder"
          v-model="value"
          :minlength="minLength()"
          ></app-form-textarea>
          <app-heading level="h7">{{ $t('max_length') }} <span :style="{'color': value.length === maxCaracteres ? 'red' : '#000000'}">{{ value.length }} de {{ maxCaracteres }}</span></app-heading>
      </div>
      <div class="input-text" v-else>
        <div class="center-text-input">
          <app-form-input v-if="question.mask !== 'tel'"
            secondary
            border-solid
            v-model="value"
            :placeholder="question.placeholder"
            :minlength="minLength()"
          ></app-form-input>
          <vue-tel-input v-if="question.mask === 'tel'"
            v-model="value"
            :autoFormat="false"
            :inputOptions="optionsPhone"
            :autoDefaultCountry="false"
            :validCharactersOnly="true"
            :defaultCountry="languageTel"
            ></vue-tel-input>
          <div class="alert">
            <p class="alert-message" v-if="alertMessage">{{ alertMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
export default {
  name: 'apptext',
  props: ['colors', 'question'],
  components: {
    'app-form-textarea': () => import('@binds-tech/binds-design-system/src/components/Form/Textarea'),
    'app-form-input': () => import('@binds-tech/binds-design-system/src/components/Form/Input'),
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    VueTelInput
  },
  data () {
    return {
      value: '',
      alertMessage: false,
      optionsPhone: {
        placeholder: this.question.placeholder ? this.question.placeholder : 'Placeholder',
        autofocus: true,
        required: this.question.required,
        showDialCode: true,
        type: 'tel'
      },
      maxCaracteres: this.question.maxLength !== 0 ? this.question.maxLength : 750,
      textRemaining: {
        maxCount: this.question.maxLength !== 0 ? this.question.maxLength : 750,
        remainingCount: this.question.maxLength !== 0 ? this.question.maxLength : 750
      }
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit()
      }
    })
  },
  watch: {
    value: function (newValue, oldValue) {
      const newTextValue = newValue.replace(/[^A-zÀ-ú \n 0-9]/gi, '') // regex para desconsiderar emoticons na contagem
      const lengthValue = this.question.maxLength !== 0 ? this.question.maxLength : 750
      if (newTextValue.length <= lengthValue) {
        this.value = newValue
      } else {
        this.value = newValue.substring(0, lengthValue)
      }
      this.textValue()
    }
  },
  computed: {
    survey () {
      const survey = this.$store.getters['survey/getSurvey']
      return survey
    },
    languageTel () {
      let languageTel
      if (this.question.language === 'pt-br') {
        languageTel = 'BR'
      } else if (this.question.language === 'en-us') {
        languageTel = 'US'
      } else {
        languageTel = 'ES'
      }
      return languageTel
    }
  },
  methods: {
    submit () {
      let errorsInput = false
      if (this.question.mask === 'email') {
        if (this.question.required) {
          if (this.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null && this.value !== '') {
            this.alertMessage = this.$i18n.t('referral.alert_email')
            errorsInput = true
            const timeError = setInterval(() => {
              this.alertMessage = false
              clearInterval(timeError)
            }, 5000)
          } else {
            errorsInput = false
          }
        }

        if (!this.question.required && this.value !== '') {
          if (this.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
            this.alertMessage = this.$i18n.t('referral.alert_email')
            errorsInput = true
            const timeError = setInterval(() => {
              this.alertMessage = false
              clearInterval(timeError)
            }, 5000)
          } else {
            errorsInput = false
          }
        }
      }
      if (!errorsInput) { this.nextQuestion() }
    },
    nextQuestion () {
      const data = { text: this.value, rating: 100 }
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
      this.value = ''
    },
    minLength () {
      let length
      if (this.question.required) {
        length = this.question.minLength.toString()
      } else {
        length = '0'
      }
      return length
    },
    textValue () {
      const newTextValue = this.value.replace(/[^A-zÀ-ú \n 0-9]/gi, '')
      this.textRemaining.remainingCount = this.textRemaining.maxCount - newTextValue.length
      if (this.question.required && (newTextValue.length >= this.question.minLength) && this.question.mask !== 'tel') {
        this.$root.$emit('show-btn', true)
      } else {
        this.$root.$emit('show-btn', false)
      }

      if (this.question.required && newTextValue.length === 0) {
        this.$root.$emit('show-btn', false)
      }

      if (this.question.mask === 'email') {
        this.value = this.value.trim()
        const inputEmail = document.querySelector('.user-click')
        inputEmail.onpaste = e => e.preventDefault()
      }

      if (this.question.required && this.question.mask === 'tel') {
        if (newTextValue.length >= 11) {
          this.$root.$emit('show-btn', true)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.comment {
  height: 370px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-comment {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input-wrapper {
      width: 90%;
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        width: 100%;
        height: 100%;
        .input {
          width: 100%;
          height: 95%;
          border-radius: 5px;
          color: #000000;
          font-weight: normal;
          font-size: 16px;
        }
      }
    }
  }
  .input-text {
    width: 90%;
    .center-text-input {
      width: 100%;
      .text-field {
        .text-field__input {
          border: 1px solid #cacaca;
          border-radius: 3px;
          .user-click {
            font-size: 16px;
            color: #000000 !important;
          }
        }
      }
      .alert {
        margin-top: 10px;
        padding-left: 5px;
        .alert-message {
          font-size: 14px;
          text-align: center;
          color: #D91212ed;
        }
      }
    }
  }
}
.vue-tel-input {
  height: 50px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #cacaca !important;
  .vti__input {
    font-size: 16px;
  }
  .vti__input:not(input.field) {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
}

// responsivity for mobile
@media only screen and (max-width: 850px) {
  .comment {
    height: var(--body-height) !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-comment {
      min-height: 60%;
      max-height: 75%;
    }
  }
}
</style>
